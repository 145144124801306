* {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(19, 21, 37);
}

@media screen and (max-width: 525px) {
  body {
    overflow: none;
  }
}

.buyButton {
  padding: 2rem 6rem;
  border: none;
  outline: none;
  color: rgb(240, 139, 88);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 3rem;
  border: solid 0.1rem;
  font-size: 2rem;
  margin-bottom: 5rem;
}

.buyButton:after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #262841;
  left: 0;
  top: 0;
  border-radius: 3rem;
}

.buyButton:before {
  content: "";
  background: linear-gradient(
    45deg,
    rgb(255, 99, 20),
    rgb(212, 65, 97),
    rgb(138, 104, 211)
  );
  position: absolute;
  top: -5px;
  left: -5px;
  background-size: 600%;
  z-index: -1;
  width: calc(102% + 4px);
  height: calc(105% + 4px);
  filter: blur(8px);
  animation: glowing 30s linear infinite;
  transition: opacity 1s ease-in-out;
  border-radius: 3rem;
  opacity: 1;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.buyButton:hover:before {
  opacity: 1;
}

.buyButton:active:after {
  background: transparent;
}

.buyButton:active {
  color: #000;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: center;
}

.navbar {
  background-color: rgb(255, 99, 20);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: left;
  padding-left: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  font-size: 2rem;
  letter-spacing: 0.01rem;
  padding-left: 10rem;
}

@media screen and (max-width: 525px) {
  .navbar {
    justify-content: center;
    padding: 0;
    padding-bottom: 0.2rem;
  }
  .logo2 {
    width: 100%;
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.mainText {
  text-align: center;
  color: white;
  margin-top: 1rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.FROG {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.frog {
  background-image: linear-gradient(
    45deg,
    rgb(255, 99, 20),
    rgb(212, 65, 97),
    rgb(138, 104, 211)
  );
  background-clip: text;
  color: transparent;
}
.smallText {
  color: rgb(197, 197, 197);
  font-size: 2rem;
}

.logo2 {
  width: 30%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2rem;
}

@media screen and (max-width: 525px) {
  .logo2 {
    width: 90%;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 1320px) {
  .logo2 {
    width: 40%;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 1145px) {
  .FROG {
    font-size: 3rem;
  }
  .mainText {
    width: 80%;
    margin: auto;
  }
  .smallText {
    font-size: 1.5rem;
  }
  .buyButton {
    margin-top: 7%;
    padding: 2rem;
    font-size: 1.4rem;
  }
}
.joinUs {
  color: white;
}

a::after {
  color: white;
}

a::visited {
  color: white;
}

a {
  color: white;
  text-decoration: none;
}
